import * as React from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import axios from 'axios';

import cartCreateMutation from '../graphql/CartCreate.graphql';
import RetrieveCart from '../graphql/RetrieveCart.graphql';
import AddToCart from '../graphql/AddToCart.graphql';
import UpdateLineItem from '../graphql/UpdateLineItem.graphql';
import RemoveLineItem from '../graphql/RemoveLineItem.graphql';


const client = Client.buildClient({
        domain: process.env.GATSBY_MYSHOPIFY_URL,
        storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
    },
    fetch
)

const defaultValues = {
    cart: [],
    isOpen: false,
    loading: false,
    onOpen: () => {},
    onClose: () => {},
    addVariantToCart: () => {},
    buyNow: () => {},
    removeLineItem: () => {},
    updateLineItem: () => {},
    client,
    checkout: {
        lines: {
            edges: []
        }
    },
}



export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `headless_channel_cart_id`

export const StoreProvider = ({ children }) => {
    const [checkout, setCheckout] = React.useState(defaultValues.checkout);
    const [isOpen, setIsOpen] = React.useState(defaultValues.isOpen);
    const [loading, setLoading] = React.useState(defaultValues.loading);

    const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)

    const setCheckoutItem = (checkout) => {
        if (isBrowser) {
            localStorage.setItem(localStorageKey, checkout.id)
        }
        setCheckout(checkout)
    }

    React.useEffect(() => {
        const initializeCheckout = async () => {
            const existingCheckoutID = isBrowser ? localStorage.getItem(localStorageKey) : null;
            
            if (existingCheckoutID && existingCheckoutID !== `null`) {
                try {
                    const existingCheckout = await getCart(existingCheckoutID)
                
                    if (!existingCheckout.completedAt) {
                        setCheckoutItem(existingCheckout)
                        return
                    }
                } catch (e) {
                    localStorage.setItem(localStorageKey, null)
                }
            }

            const newCheckout = await createCart();
            if(newCheckout !== null) {
                setCheckoutItem(newCheckout)
            }
        }

        initializeCheckout()

    }, []);
    
    const createCart = async (cartInput = {}) => {

        const variables = { input: cartInput };
        const query = cartCreateMutation.loc.source.body;
        
        try {
            const response = await axios.post(`https://${process.env.GATSBY_MYSHOPIFY_URL}/api/2025-01/graphql.json`, 
                {
                    query,
                    variables,
                }, 
                {
                    headers: {
                        'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
                        'Content-Type': 'application/json',
                    },
                }
            );
        
            if (response.data.errors) {
                //console.error('GraphQL Errors:', response.data.errors);
                return null;
            }
        
            const { cartCreate } = response.data.data;
        
            if (cartCreate.userErrors.length > 0) {
                //console.error('User Errors:', cartCreate.userErrors);
                return null;
            }
        
            //console.log('Cart Created:', cartCreate.cart);
            return cartCreate.cart;
        } catch (error) {
            //console.error("Error creating cart:", error.response?.data || error.message);
            return null;
        }
    }

    const getCart = async (cartId) => {
        const query = RetrieveCart.loc.source.body;

        try {
            const response = await axios.post(`https://${process.env.GATSBY_MYSHOPIFY_URL}/api/2025-01/graphql.json`, 
                {
                    query,
                    variables: { id: cartId },
                },
                {
                    headers: {
                        'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
                        'Content-Type': 'application/json',
                    },
                }
            );
      
            return response?.data?.data?.cart;
        } catch (error) {
            //console.error('Error fetching cart:', error);
            return { error: error.message };
        }
    }

    const buyNow = async (variantId, quantity) => {
        setLoading(true)
        const cartId = checkout?.id;

        const variables = {
            cartId,
            lines: [
              {
                merchandiseId: variantId,
                quantity: quantity
              }
            ]
          };
        const query = AddToCart.loc.source.body;
        
        try {
            const response = await axios.post(`https://${process.env.GATSBY_MYSHOPIFY_URL}/api/2025-01/graphql.json`, 
                {
                    query,
                    variables,
                }, 
                {
                    headers: {
                        'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
                        'Content-Type': 'application/json',
                    },
                }
            );
        
            if (response.data.errors) {
                //console.error('GraphQL Errors:', response.data.errors);
                return null;
            }
        
            const { cartLinesAdd } = response.data.data;
        
            if (cartLinesAdd.userErrors.length > 0) {
                //console.error('User Errors:', cartLinesAdd.userErrors);
                return null;
            }
            
            if(process.env.GATSBY_STORE !== "PH") {
                window.location.href = checkout?.checkoutUrl.replace("blackboughswim.com", "checkout.blackboughswim.com");
            } else {
                window.location.href = checkout?.checkoutUrl.replace("blackboughswim.ph", "checkout.blackboughswim.ph");
            }
        } catch (error) {
            //console.error("Error creating cart:", error.response?.data || error.message);
            return null;
        }

        // Redirect to the Shopify checkout URL
        
    };


    const onOpen = () => {
        setIsOpen(true);
    }

    const onClose = () => {
        setIsOpen(false);
    }
    
    const addVariantToCart = async (variantId, quantity) => {
        setLoading(true)
        const cartId = checkout?.id;

        const variables = {
            cartId,
            lines: [
              {
                merchandiseId: variantId,
                quantity: quantity
              }
            ]
          };
        const query = AddToCart.loc.source.body;
        
        try {
            const response = await axios.post(`https://${process.env.GATSBY_MYSHOPIFY_URL}/api/2025-01/graphql.json`, 
                {
                    query,
                    variables,
                }, 
                {
                    headers: {
                        'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
                        'Content-Type': 'application/json',
                    },
                }
            );
        
            if (response.data.errors) {
                //console.error('GraphQL Errors:', response.data.errors);
                return null;
            }
        
            const { cartLinesAdd } = response.data.data;
        
            if (cartLinesAdd.userErrors.length > 0) {
                //console.error('User Errors:', cartLinesAdd.userErrors);
                return null;
            }
        
            //console.log('Add to Cart:', cartLinesAdd.cart);
            setCheckout(cartLinesAdd.cart)
            setLoading(false)
            setDidJustAddToCart(true)
            setIsOpen(true)
            setTimeout(() => setDidJustAddToCart(false), 3000)

            return cartLinesAdd.cart;
        } catch (error) {
            //console.error("Error creating cart:", error.response?.data || error.message);
            return null;
        }
    }


    const removeLineItem = async (lineItemID) => {
        setLoading(true)
        const cartId = checkout?.id;

        const variables = {
            cartId,
            lineIds: [lineItemID]
        };
          
        const query = RemoveLineItem.loc.source.body;
        
        try {
            const response = await axios.post(`https://${process.env.GATSBY_MYSHOPIFY_URL}/api/2025-01/graphql.json`, 
                {
                    query,
                    variables,
                }, 
                {
                    headers: {
                        'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
                        'Content-Type': 'application/json',
                    },
                }
            );
        
            if (response.data.errors) {
                //console.error('GraphQL Errors:', response.data.errors);
                return null;
            }
        
            const { cartLinesRemove } = response.data.data;
        
            if (cartLinesRemove.userErrors.length > 0) {
                //console.error('User Errors:', cartLinesRemove.userErrors);
                return null;
            }
        
            //console.log('Add to Cart:', cartLinesRemove.cart);
            setCheckout(cartLinesRemove.cart)
            setLoading(false)
            
            return cartLinesRemove.cart;
        } catch (error) {
            //console.error("Error creating cart:", error.response?.data || error.message);
            return null;
        }
    }

    const updateLineItem = async (lineItemID, quantity) => {
        setLoading(true)
        const cartId = checkout?.id;

        const variables = {
            cartId,
            lines: [
              {
                id: lineItemID,
                quantity: quantity
              }
            ]
        };
          
        const query = UpdateLineItem.loc.source.body;
        
        try {
            const response = await axios.post(`https://${process.env.GATSBY_MYSHOPIFY_URL}/api/2025-01/graphql.json`, 
                {
                    query,
                    variables,
                }, 
                {
                    headers: {
                        'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
                        'Content-Type': 'application/json',
                    },
                }
            );
        
            if (response.data.errors) {
                //console.error('GraphQL Errors:', response.data.errors);
                return null;
            }
        
            const { cartLinesUpdate } = response.data.data;
        
            if (cartLinesUpdate.userErrors.length > 0) {
                //console.error('User Errors:', cartLinesUpdate.userErrors);
                return null;
            }
        
            //console.log('Add to Cart:', cartLinesUpdate.cart);
            setCheckout(cartLinesUpdate.cart)
            setLoading(false)
            
            return cartLinesUpdate.cart;
        } catch (error) {
            //console.error("Error creating cart:", error.response?.data || error.message);
            return null;
        }
    }

    return (
        <StoreContext.Provider
            value={{
                ...defaultValues,
                addVariantToCart,
                buyNow,
                removeLineItem,
                updateLineItem,
                checkout,
                loading,
                didJustAddToCart,
                isOpen,
                onOpen,
                onClose,
            }}
        >
            {children}
        </StoreContext.Provider>
    )
}
