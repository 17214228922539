export const getMetricValues = (value, metric) => {
  const convertInchesToCMs = (metricVals) => {
    const ranges = metricVals.split("-")
    return ranges.map((range) => Math.round(parseInt(range) * 2.54)).join("-") + "cm"
  }

  if (!value) return ""

  const finalVal =
    metric === "cm" && value.includes('"')
      ? convertInchesToCMs(value)
      : value

  return finalVal
}

export const getProductRating = (data) => {
  const totalRatings = data?.ratings && data?.ratings.reduce((sum, {star, total}) => sum + (star * total), 0)
  const totalReviews = data?.ratings && data?.ratings.reduce((sum, {total}) => sum + total, 0)
  const average = totalRatings / totalReviews

  return { totalRatings, totalReviews, average }
}

export const convertFileSizeToMB = (data) => {
  return Math.round((data / 1024) / 1024).toFixed(2) + "MB"
}


/**
 * Formats a currency according to the user's locale
 * @param {string} currency The ISO currency code
 * @param {number} value The amount to format
 * @returns
 */
export const formatPrice = (currency, value) =>
    Intl.NumberFormat("en-US", {
        currency,
        minimumFractionDigits: 2,
        style: "currency",
    }).format(value)

export const productFormattedPrice = (currency, value) => {
    if (!currency) return

    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
    }).format(value);
};

export const getCurrencySymbol = (currency, locale = undefined) => {
    if (!currency) {
        return
    }

    const formatter = Intl.NumberFormat(locale, {
        currency,
        style: "currency",
    })
    const parts = formatter.formatToParts(100)
    const { value: symbol } = parts.find((part) => part.type === "currency")
    const formatted = formatter.format(100)
    const symbolAtEnd = formatted.endsWith(symbol)
    return { symbol, symbolAtEnd }
}

export const getPercentage = (currentValue, maxValue) => {
  if(maxValue === 0) return 0
  return (currentValue / maxValue) * 100
}

export const trimStorefrontId = (string, replaceTrimmedStringsWith = "", regexString ="gid.*/") => {
  if(string !== null) {
    const regex = new RegExp(regexString)
    return string?.replace(regex, replaceTrimmedStringsWith)
  }
  return ""
}

export const getCreditCardType = (val) => {
  const cardNumber = parseInt(val)

  const visa = /^4/;
  const masterCard = /^5[1-5]/;
  const amex = /^(34|37)/;
  const discover = /^6/;
  const jcb = /^35/;

  if (visa.test(cardNumber)) {
    return "Visa";
  } else if (masterCard.test(cardNumber)) {
    return "MasterCard";
  } else if (amex.test(cardNumber)) {
    return "American Express";
  } else if (discover.test(cardNumber)) {
    return "Discover";
  } else if (jcb.test(cardNumber)) {
    return "JCB";
  } else {
    return false;
  }
}

export const getAllMonths = () => {
  return [
    {id: 0, label: "January"},
    {id: 1, label: "February"},
    {id: 2, label: "March"},
    {id: 3, label: "April"},
    {id: 4, label: "May"},
    {id: 5, label: "June"},
    {id: 6, label: "July"},
    {id: 7, label: "August"},
    {id: 8, label: "September"},
    {id: 9, label: "October"},
    {id: 10, label: "November"},
    {id: 11, label: "December"},
  ]
}