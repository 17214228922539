import axios from "axios";

const SHOPIFY_API_URL = `https://${process.env.GATSBY_MYSHOPIFY_URL}/api/2025-01/graphql.json`;
const SHOPIFY_HEADERS = {
  "Content-Type": "application/json",
  "X-Shopify-Storefront-Access-Token": process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
};

// Customer Registration
export const registerCustomer = async (firstName, lastName, email, password) => {
  const query = `
    mutation {
      customerCreate(input: {
        firstName: "${firstName}",
        lastName: "${lastName}",
        email: "${email}",
        password: "${password}"
      }) {
        customer {
          id
          email
        }
        userErrors {
          field
          message
        }
      }
    }
  `;

  const response = await axios.post(SHOPIFY_API_URL, { query }, { headers: SHOPIFY_HEADERS });
  return response.data;
};

// Customer Login
export const loginCustomer = async (email, password) => {
  const query = `
    mutation {
      customerAccessTokenCreate(input: {
        email: "${email}",
        password: "${password}"
      }) {
        customerAccessToken {
          accessToken
          expiresAt
        }
        userErrors {
          field
          message
        }
      }
    }
  `;

  const response = await axios.post(SHOPIFY_API_URL, { query }, { headers: SHOPIFY_HEADERS });
  return response.data;
};

// Fetch Customer Data
export const getCustomerData = async (accessToken) => {
  const query = `
    query {
      customer(customerAccessToken: "${accessToken}") {
        firstName
        lastName
        email
        phone
      }
    }
  `;

  const response = await axios.post(SHOPIFY_API_URL, { query }, { headers: SHOPIFY_HEADERS });
  return response.data;
};

// Update Customer
export const updateCustomer = async (accessToken, firstName, lastName, phone) => {
  const query = `
    mutation {
      customerUpdate(customerAccessToken: "${accessToken}", customer: {
        firstName: "${firstName}",
        lastName: "${lastName}",
        phone: "${phone}",

      }) {
        customer {
          firstName
          lastName
          phone
        }
        userErrors {
          field
          message
        }
      }
    }
  `;

  const response = await axios.post(SHOPIFY_API_URL, { query }, { headers: SHOPIFY_HEADERS });
  return response.data;
};

//get customer order details
export const getCustomerOrders = async (accessToken) => {
    const query = `
        query getCustomerOrders {
            customer(customerAccessToken: "${accessToken}") {
                orders(first: 50, reverse: true, sortKey: ID) {
                    edges {
                        node {
                            id
                            name
                            email
                            orderNumber
                            processedAt
                            financialStatus
                            fulfillmentStatus
                            currencyCode
                            subtotalPrice {
                                amount
                                currencyCode
                            }
                            currentTotalShippingPrice {
                                amount
                                currencyCode
                            }
                            totalPrice {
                                amount
                                currencyCode
                            }
                            originalTotalPrice {
                                amount
                                currencyCode
                            }
                            shippingAddress {
                                firstName
                                lastName
                                address1
                                city
                                province
                                country
                                zip
                                phone
                                formattedArea
                            }
                            billingAddress {
                                firstName
                                lastName
                                address1
                                city
                                province
                                country
                                zip
                                phone
                                formattedArea
                            }
                            lineItems(first: 30) {
                                edges {
                                    node {
                                        title
                                        quantity
                                        originalTotalPrice {
                                            amount
                                            currencyCode
                                        }
                                        variant {
                                            id
                                            sku
                                            image {
                                                url
                                            }
                                            selectedOptions {
                                                name
                                                value
                                            }
                                            price {
                                                amount
                                                currencyCode
                                            }
                                        }
                                    }
                                }
                            }
                            successfulFulfillments(first: 3) {
                                trackingCompany
                                trackingInfo {
                                    number
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const response = await axios.post(SHOPIFY_API_URL, { query }, { headers: SHOPIFY_HEADERS });
    return response.data;
};

//get customer address
export const getShippingAddress = async (accessToken) => {
    const query = `
        query getCustomerAddresses {
            customer(customerAccessToken: "${accessToken}") {
                addresses(first: 20) {
                    edges {
                        node {
                            id
                            address1
                            address2
                            city
                            province
                            country
                            zip
                            phone
                            company
                        }
                    }
                }
                defaultAddress {
                    id
                }
            }
        }
    `;

    const response = await axios.post(SHOPIFY_API_URL, { query }, { headers: SHOPIFY_HEADERS });
    return response.data;
};

export const addShippingAddress = async (accessToken, address1, address2, city, province, country, zip, phone, company) => {
    const query = `
        mutation addAddress {
            customerAddressCreate(customerAccessToken: "${accessToken}", address: {
                    address1: "${address1}",
                    address2: "${address2}",
                    city: "${city}",
                    province: "${province}",
                    country: "${country}",
                    zip: "${zip}",
                    phone: "${phone}",
                    company: "${company}",
                }) {
                    customerAddress {
                        id
                        address1
                        address2
                        city
                        province
                        country
                        zip
                        phone
                        company
                    }
                    userErrors {
                        field
                        message
                    }
            }
        }
    `;

    const response = await axios.post(SHOPIFY_API_URL, { query }, { headers: SHOPIFY_HEADERS });
    return response.data;
};


export const updateDefaultShippingAddress = async (accessToken, addressId) => {
    const query = `
        mutation  {
            customerDefaultAddressUpdate(customerAccessToken: "${accessToken}", addressId: "${addressId}") {
                    customer {
                        defaultAddress {
                            id
                            address1
                            city
                            country
                        }
                    }
                    userErrors {
                        field
                        message
                    }
            }
        }
    `;

    const response = await axios.post(SHOPIFY_API_URL, { query }, { headers: SHOPIFY_HEADERS });
    return response.data;
};



export const editShippingAddress = async (accessToken, addressId, address1, address2, city, province, country, zip, phone, company) => {
    const query = `
        mutation updateAddress {
            customerAddressUpdate(customerAccessToken: "${accessToken}", id: "${addressId}", address: {
                    address1: "${address1}",
                    address2: "${address2}",
                    city: "${city}",
                    province: "${province}",
                    country: "${country}",
                    zip: "${zip}",
                    phone: "${phone}",
                    company: "${company}",
                }) {
                    customerAddress {
                        id
                        address1
                        address2
                        city
                        province
                        country
                        zip
                        phone
                        company
                    }
                    userErrors {
                        field
                        message
                    }
            }
        }
    `;

    const response = await axios.post(SHOPIFY_API_URL, { query }, { headers: SHOPIFY_HEADERS });
    return response.data;
};


export const removeShippingAddress = async (accessToken, addressId) => {
    const query = `
        mutation customerAddressDelete {
            customerAddressDelete(customerAccessToken: "${accessToken}", id: "${addressId}") {
                deletedCustomerAddressId
                userErrors {
                field
                message
                }
            }
        }
    `;

    const response = await axios.post(SHOPIFY_API_URL, { query }, { headers: SHOPIFY_HEADERS });
    return response.data;
};