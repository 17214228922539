import React from "react";
import './src/styles/global.css';
import 'swiper/swiper-bundle.css';
import "swiper/css"
import "swiper/css/pagination"
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'
import "react-toastify/dist/ReactToastify.css"

import { QuickBuyProvider } from './src/context/QuickBuyContext.jsx'
import { StoreProvider } from './src/context/StoreContext.jsx'
import { AllProductsProvider } from './src/context/AllProductsContext.jsx'
import { UserProvider } from './src/context/UserContext.jsx'
import { ToastContainer } from "react-toastify"

//import { ApolloProvider } from '@apollo/client';
//import client from './src/context/apollo';


export const wrapRootElement = ({ element }) => (
    <AllProductsProvider>
        <QuickBuyProvider>
            <UserProvider>
                <StoreProvider>{element}</StoreProvider>
            </UserProvider>
        </QuickBuyProvider>
        <ToastContainer
            position='top-right'
            autoClose={3000} 
            hideProgressBar
            closeButton={false}
            toastClassName={() => 'p-0'}
        />
    </AllProductsProvider>
    //<ApolloProvider client={client}></ApolloProvider>
)