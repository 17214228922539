// mocks
import MTT from "@images/mocks/Martini Triangle Top.webp"
import FH from "@images/mocks/Floppy Hat.webp"
import CST from "@images/mocks/Chloe Side Tie Cheeky Bottoms.webp"
import RCP from "@images/mocks/Regina Cargo Pants.webp"
import VRJ from "@images/mocks/Vanilla Rum Jacquard.webp"

import collection1 from "@images/mocks/collection-1.webp"
import collection2 from "@images/mocks/collection-2.webp"
import collection3 from "@images/mocks/collection-3.webp"
import collection4 from "@images/mocks/collection-4.webp"
import collection5 from "@images/mocks/collection-5.webp"
import collection6 from "@images/mocks/collection-6.webp"

import search_featured_1 from "@images/Search/country-club-crush.jpg"
import search_featured_2 from "@images/Search/wild-hearts.jpg"


import lookbook1 from "@images/mocks/lookbook-1.webp"
import lookbook2 from "@images/mocks/lookbook-2.webp"
import lookbook3 from "@images/mocks/lookbook-3.webp"
import lookbook4 from "@images/mocks/lookbook-4.webp"

import discoverSwim from "@images/Home/discover-your-style/swim.png"
import discoverMens from "@images/Home/discover-your-style/mens.png"
import discoverAccessories from "@images/Home/discover-your-style/accessories.png"
import discoverApparel from "@images/Home/discover-your-style/apparel.png"

import print1 from "@images/mocks/color-palettes/print-01.webp"
import print2 from "@images/mocks/color-palettes/print-02.webp"
import print3 from "@images/mocks/color-palettes/print-03.webp"
import print4 from "@images/mocks/color-palettes/print-04.webp"
import print5 from "@images/mocks/color-palettes/print-05.webp"
import print6 from "@images/mocks/color-palettes/print-06.webp"
import print7 from "@images/mocks/color-palettes/print-07.webp"
import print8 from "@images/mocks/color-palettes/print-08.webp"
import print9 from "@images/mocks/color-palettes/print-09.webp"
import print10 from "@images/mocks/color-palettes/print-10.webp"
import print11 from "@images/mocks/color-palettes/print-11.webp"
import print12 from "@images/mocks/color-palettes/print-12.webp"
import print13 from "@images/mocks/color-palettes/print-13.webp"

import emptyThumb1 from '@images/mocks/empty-bag-thumb-1.webp'
import emptyThumb2 from '@images/mocks/empty-bag-thumb-2.webp'
import emptyThumb3 from '@images/mocks/empty-bag-thumb-3.webp'
import emptyThumb4 from '@images/mocks/empty-bag-thumb-4.webp'

import cheeky from "@images/Products/cheeky.svg"
import midRise from "@images/Products/mid-rise.svg"
import runsTrue from "@images/Products/runs-true.svg"

import lb01 from "@images/mocks/lookbook/lb-01.webp"
import lb02 from "@images/mocks/lookbook/lb-02.webp"
import lb03 from "@images/mocks/lookbook/lb-03.webp"
import lb04 from "@images/mocks/lookbook/lb-04.webp"
import lb05 from "@images/mocks/lookbook/lb-05.webp"
import lb06 from "@images/mocks/lookbook/lb-06.webp"
import lb07 from "@images/mocks/lookbook/lb-07.webp"
import lb08 from "@images/mocks/lookbook/lb-08.webp"
import lb09 from "@images/mocks/lookbook/lb-09.webp"
import lb10 from "@images/mocks/lookbook/lb-10.webp"
import lb11 from "@images/mocks/lookbook/lb-11.webp"
import lb12 from "@images/mocks/lookbook/lb-12.webp"
import lb13 from "@images/mocks/lookbook/lb-13.webp"
import lb14 from "@images/mocks/lookbook/lb-14.webp"
import lbm1 from "@images/mocks/lookbook/lbmodal01.webp"
import lbm2 from "@images/mocks/lookbook/lbmodal02.webp"
import lbm3 from "@images/mocks/lookbook/lbmodal03.webp"
import lbm4 from "@images/mocks/lookbook/lbmodal04.webp"

import colCardImg from "@images/mocks/collection-card-image.webp"

import blogPost1 from "@images/mocks/blogpost/blog-thumb-1.webp"
import blogPost2 from "@images/mocks/blogpost/blog-thumb-2.webp"
import blogPost3 from "@images/mocks/blogpost/blog-thumb-3.webp"

// order history
import mockImage from '@images/mocks/mock-back-view.webp'

// rewards
import mockReward1 from '@images/mocks/rewards/mock-reward-1.png'
import mockReward2 from '@images/mocks/rewards/mock-reward-2.png'
import mockReward3 from '@images/mocks/rewards/mock-reward-3.png'
import mockReward4 from '@images/mocks/rewards/mock-reward-4.png'
import mockReward5 from '@images/mocks/rewards/mock-reward-5.png'

export const MockProducts = [
  {
    id: 0,
    image: MTT,
    name: "Martini Triangle Top",
    sub: "Vanilla Rum Jacquard",
    tags: ['NEW ARRIVAL','BEST SELLER'],
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 1,
    image: FH,
    name: "Althea Bucket Hat",
    sub: "Rose",
    tags: ['NEW ARRIVAL','BEST SELLER'],
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 2,
    image: CST,
    name: "Chloe Side Tie Cheeky Bottoms",
    sub: "Sour Slush",
    tags: ['NEW ARRIVAL','BEST SELLER'],
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 3,
    image: RCP,
    name: "Regina Cargo Pants",
    sub: "Basil Smash",
    tags: ['NEW ARRIVAL','BEST SELLER'],
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 4,
    image: VRJ,
    name: "Vanilla Rum Jacquard",
    sub: "Basil Smash",
    tags: ['NEW ARRIVAL','BEST SELLER'],
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 5,
    image: MTT,
    name: "Martini Triangle Top",
    sub: "Vanilla Rum Jacquard",
    tags: ['NEW ARRIVAL','BEST SELLER'],
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 6,
    image: FH,
    name: "Floppy Hat",
    sub: "Vanilla Rum Jacquard",
    tags: ['NEW ARRIVAL','BEST SELLER'],
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 7,
    image: CST,
    name: "Vanilla Rum Jacquard",
    tags: ['NEW ARRIVAL','BEST SELLER'],
    sub: "Basil Smash",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
]

export const MockCollections = [
  {
    id: 0,
    image: collection1,
    name: "Martini Triangle Top",
    sub: "Vanilla Rum Jacquard",
    buttonLabel: "CASA DEL MAR",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 1,
    image: collection2,
    name: "Martini Triangle Top",
    sub: "Vanilla Rum Jacquard",
    buttonLabel: "ROMANCE BOULEVARD",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 2,
    image: collection3,
    name: "Martini Triangle Top",
    sub: "Vanilla Rum Jacquard",
    buttonLabel: "SPELLBOUND",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 3,
    image: collection4,
    name: "Martini Triangle Top",
    sub: "Vanilla Rum Jacquard",
    buttonLabel: "SUNSET BEACH",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 4,
    image: search_featured_1,
    name: "COUNTRY CLUB CRUSH",
    sub: "COUNTRY CLUB CRUSH",
    buttonLabel: "COUNTRY CLUB CRUSH",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
    to: "/collections/the-country-club-crush-collection/"
  },
  {
    id: 5,
    image: search_featured_2,
    name: "WILD HEARTS",
    sub: "WILD HEARTS",
    buttonLabel: "WILD HEARTS",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
    to: "/collections/the-wild-hearts-collection/"
  },
]

export const MockLookbooks = [
  {
    id: 0,
    image: lookbook1,
    name: "Martini Triangle Top",
    sub: "Vanilla Rum Jacquard",
    buttonLabel: "Shop the look",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 1,
    image: lookbook2,
    name: "Martini Triangle Top",
    sub: "Vanilla Rum Jacquard",
    buttonLabel: "Shop the look",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 2,
    image: lookbook3,
    name: "Martini Triangle Top",
    sub: "Vanilla Rum Jacquard",
    buttonLabel: "Shop the look",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
  {
    id: 3,
    image: lookbook4,
    name: "Martini Triangle Top",
    sub: "Vanilla Rum Jacquard",
    buttonLabel: "Shop the look",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
  },
]

export const MockDiscover = [
  {
    id: 0,
    image: discoverSwim,
    name: "Swim",
    sub: "",
    buttonLabel: "SWIM",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
    url: '/collections/all',
  },
  {
    id: 1,
    image: discoverMens,
    name: "Men's",
    sub: "",
    buttonLabel: "MEN'S",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
    url: '/collections/mens-collection',
  },
  {
    id: 2,
    image: discoverAccessories,
    name: "Accessories",
    sub: "",
    buttonLabel: "ACCESSORIES",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
    url: '/collections/accessories',
  },
  {
    id: 3,
    image: discoverApparel,
    name: "Apparel",
    sub: "",
    buttonLabel: "APPAREL",
    finalPrice: "90",
    price: "120",
    hug: true,
    isDiscounted: true,
    isNew: true,
    finalSale: true,
    url: '/collections/apparel',
  },
]

export const MockPrintedPalettes = [
  { id: 'print-01', name: 'Lapis Lazuli', image: print1, value: 'filter_lapis-lazuli' },
  { id: 'print-02', name: 'Summer Picnic', image: print2, value: 'filter_summer-picnic' },
  { id: 'print-03', name: 'Azure Dove', image: print3, value: 'filter_azure-dove' },
  { id: 'print-04', name: 'Flora and Fauna', image: print4, value: 'filter_flora-and-fauna' },
  { id: 'print-05', name: 'Dolly', image: print5, value: 'filter_dolly' },
  { id: 'print-06', name: 'Bonfire', image: print6, value: 'filter_bonfire' },
  { id: 'print-07', name: 'Sweetheart', image: print7, value: 'filter_sweetheart' },
  { id: 'print-08', name: 'Marabella', image: print8, value: 'filter_marabella' },
  { id: 'print-09', name: 'Mayflowers', image: print9, value: 'filter_may-flowers' },
  { id: 'print-10', name: 'Buttermilk', image: print10, value: 'filter_buttermilk' },
  { id: 'print-11', name: 'Pastel Party', image: print11, value: 'filter_pastel-party' },
  { id: 'print-12', name: 'Day Dream', image: print12, value: 'filter_day-dream' },
  { id: 'print-13', name: 'Picnic Party', image: print13, value: 'filter_picnic-party' },
]


export const MockIconDetails = [
  {label: 'Cheeky Adjustable Coverage', image: cheeky},
  {label: 'Mid-Rise', image: midRise},
  {label: 'Runs True to Size', image: runsTrue},
]

export const mockReviews = [
  {
    title: 'Supportive tops!',
    rating: 5,
    product: 'Lapiz Lazuli',
    review: 'First of all this print wow im so obsessed I got it in other styles! Also the Alana top is perfect for girls who need that extra support!',
    user: 'Caroline',
    date: 'Sep. 23, 2023',
  },
  {
    title: 'Please make a silk blend variant!',
    rating: 4,
    product: 'Lapiz Lazuli',
    review: 'Lightweight and heat-regulating. No snags or pilling so far after a few washes.',
    user: 'Caroline',
    date: 'Sep. 23, 2023',
  },
  {
    title: 'Beautiful!!',
    rating: 5,
    product: 'Lapiz Lazuli',
    review: 'First of all this print wow im so obsessed I got it in other styles! Also the Alana top is perfect for girls who need that extra support!',
    user: 'Caroline',
    date: 'Sep. 23, 2023',
  },
  {
    title: 'Could be better',
    rating: 3,
    product: 'Lapiz Lazuli',
    review: 'First of all this print wow im so obsessed I got it in other styles! Also the Alana top is perfect for girls who need that extra support!',
    user: 'Caroline',
    date: 'Sep. 23, 2023',
  },
  
]

export const mockEmptyBag = [
  {
    image: emptyThumb1,
    label: 'New Arrivals',
  },
  {
    image: emptyThumb2,
    label: 'Best Sellers',
  },
  {
    image: emptyThumb3,
    label: 'Selling Fast',
  },
  {
    image: emptyThumb4,
    label: 'Popular Collections',
  },
]

export const mockAnnouncements = [
  {id: 0, text: `<a href="https://blackboughswim.ph/collections/the-city-sweetheart-collection/">💌 NEW IN: Meet the Maddy Set in Uptown Girl, your new limited-edition must-have. Shop now →</a>`},
  {id: 1, text: `<a href="https://blackboughswim.com/en-ph/pages/student-discount">STUDY HARD, SLAY HARDER! Get 30% off with your Student Discount. Sign up here!</a>`},
  // {id: 0, text: `Free Shipping for $100 up`},
  // {id: 1, text: `The Holiday Gift Shop is Now Open! &#127876;&#10024; Find the Perfect Gifts Here →`}
]

export const mockBento = [
  {
    id: 1,
    image: lb01,
    videoUrl: '',
  },
  {
    id: 2,
    image: lb02,
    videoUrl: '',
  },
  {
    id: 3,
    image: lb03,
    videoUrl: '',
  },
  {
    id: 4,
    image: lb04,
    videoUrl: '',
  },
  {
    id: 5,
    image: lb05,
    videoUrl: '',
  },
  {
    id: 6,
    image: lb06,
    videoUrl: '',
  },
  {
    id: 7,
    image: lb07,
    videoUrl: '',
  },
  {
    id: 8,
    image: lb08,
    videoUrl: '',
  },
  {
    id: 9,
    image: lb09,
    videoUrl: '',
  },
  {
    id: 10,
    image: lb10,
    videoUrl: '',
  },
  {
    id: 11,
    image: lb11,
    videoUrl: '',
  },
  {
    id: 12,
    image: lb12,
    videoUrl: '',
  },
  {
    id: 13,
    image: lb13,
    videoUrl: '',
  },
  {
    id: 14,
    image: lb14,
    videoUrl: '',
  },
]

export const lbModalKit = [
  {
    id: 0,
    image: lbm1,
    name: "Candice Ruffled Cheeky Top",
    sub: "Dusk Eyelet",
    price: "$55",
  },
  {
    id: 1,
    image: lbm2,
    name: "Candice Ruffled Cheeky Bottom",
    sub: "Dusk Eyelet",
    price: "$55",
  },
  {
    id: 2,
    image: lbm3,
    name: "Alana Tie front Top",
    sub: "Dusk Eyelet",
    price: "$55",
  },
  {
    id: 3,
    image: lbm3,
    name: "Alana Tie front Bottom",
    sub: "Dusk Eyelet",
    price: "$55",
  },
  {
    id: 0,
    image: lbm1,
    name: "Candice Ruffled Cheeky Top",
    sub: "Dusk Eyelet",
    price: "$55",
  },
  {
    id: 1,
    image: lbm2,
    name: "Candice Ruffled Cheeky Bottom",
    sub: "Dusk Eyelet",
    price: "$55",
  },
  {
    id: 2,
    image: lbm3,
    name: "Alana Tie front Top",
    sub: "Dusk Eyelet",
    price: "$55",
  },
  {
    id: 3,
    image: lbm3,
    name: "Alana Tie front Bottom",
    sub: "Dusk Eyelet",
    price: "$55",
  },
]

export const collectionCards = [
  {
    id: 0,
    image: colCardImg,
    name: "The Avocado Collection",
  },
  {
    id: 1,
    image: colCardImg,
    name: "The Daisy Blooms Collection",
  },
  {
    id: 2,
    image: colCardImg,
    name: "The Tutti Frutti Collection",
  },
  {
    id: 3,
    image: colCardImg,
    name: "The Pool House Collection",
  },
  {
    id: 4,
    image: colCardImg,
    name: "The Weaves Collection",
  },
  {
    id: 5,
    image: colCardImg,
    name: "The Floral Punch Collection",
  },
  {
    id: 6,
    image: colCardImg,
    name: "Berry Cherry: The Remix Collection",
  },
  {
    id: 7,
    image: colCardImg,
    name: "The Pink Twinkle Collection",
  },
  {
    id: 8,
    image: colCardImg,
    name: "The Zooted Collection",
  },
  {
    id: 9,
    image: colCardImg,
    name: "The Spring Fling Collection",
  },
  {
    id: 10,
    image: colCardImg,
    name: "The Iced Coconut Collection",
  },
  {
    id: 11,
    image: colCardImg,
    name: "The Paradise Found Collection ",
  },
]

export const mockMoreBlogs = [
  {
    id: 0,
    thumb: blogPost1,
    title: "Plastic Waste Upcycling with Retaso",
    date: "October 25, 2024",
    tags: ["Events", "Lifestyle", "Stories"],
    content: "Blackbough Swim is thrilled to share that we are an official Plastic Waste Upcycling Donor to Retaso, a social and environmental enterprise based in the Philippines. As we aspire to become more holistically sustainable in our business practices, we are constantly looking for new avenues to minimize waste and maximize impact. Learn more about Retaso and our pledge here."
  },
  {
    id: 1,
    thumb: blogPost2,
    title: "Cleaner Coasts 2024",
    date: "October 26, 2024",
    tags: ["Events",],
    content: "This September, as we celebrate International Coastal Cleanup Day, Blackbough Swim invites you to make every wave count. In collaboration with Project CURMA, Blackbough is kicking off a two-part coastal cleanup initiative designed to protect the Philippine shorelines and aid in the rehabilitation of sea turtles."
  },
  {
    id: 2,
    thumb: blogPost3,
    title: "Where to buy the perfect swimwear from our Perfect Match",
    date: "October 27, 2024",
    tags: ["Lifestyle", "Stories"],
    content: `Shot in the vibrant beaches of Tulum, “The Perfect Match" season 2 is perfect for showcasing stylish and tropical-friendly fashion. To help them meet their perfectly ever after, contestants on this Netflix dating show have dolled themselves up with the hottest pieces from Blackbough Swim.`
  },
]

export const mockSizeVariants = [
  { title: 'S', inventoryQuantity: 0 },
  { title: 'M', inventoryQuantity: 0 },
  { title: 'L', inventoryQuantity: 0 },
]

export const mockUserAccount = {
  id: '012345678',
  name: 'Marie Devin',
  firstName: 'Marie',
  lastName: 'Devin',
  emailAddress: 'marie.devin@example.com',
  countryCode: 'US',
  mobileNumber: '555-0173',
}

export const mockPaymentMethods = [
  { id: 1, owner: "Marie Devin", type: "Visa", name: 'Visa', expirationDate: '08/29', cardNumber: "4111222233331234", last4: "1234", cvv: "079", isDefault: true },
  { id: 2, owner: "Marie Devin", type: "MasterCard", name: 'MasterCard', expirationDate: '06/27', cardNumber: "555544433335678", last4: "5678", cvv: "012", isDefault: false },
  { id: 3, owner: "Marie Devin", type: "Amex", name: 'American Express', expirationDate: '10/30', cardNumber: "3745789045459101", last4: "9101", cvv: "048", isDefault: false },
];

export const mockOrderHistory = [
  {
    category: "All",
    orders: [
      {
        orderId: "467GHJKL1124",
        orderDate: "12/02/2024",
        productsOrdered: [
          {
            productId: '0',
            productImage: mockImage,
            productName: 'Floppy Hat / Banana Bunch (Free Size)',
            variant: "Banana Bunch",
            size: "+",
            price: "$55",
            sku: "000012",
            quantity: 1,
          },
          {
            productId: '1',
            productImage: FH,
            productName: 'Martini Triangle Top / Vanilla Rum Jacquard (Medium)',
            variant: "Vanilla Rum Jacquard",
            size: "M",
            price: "$55",
            sku: "000013",
            quantity: 1,
          },
          {
            productId: '2',
            productImage: VRJ,
            productName: 'Chloe Side Tie Cheeky Bottoms / NY Noir (Medium)',
            variant: "NY Noir",
            size: "M",
            price: "$55",
            sku: "000013",
            quantity: 1,
          },
          {
            productId: '3',
            productImage: CST,
            productName: 'Regina Cargo Pants / Basil Smash (Medium)',
            variant: "Basil Smash",
            size: "M",
            price: "$55",
            sku: "000013",
            quantity: 1,
          },
        ],
        totalPrice: "$220",
        status: "OUT FOR DELIVERY",
        trackingNumber: "SEBG1307156373607108608",
      },
      {
        orderId: "1233BB45667",
        orderDate: "12/12/2024",
        productsOrdered: [
          {
            productId: '0',
            productImage: VRJ,
            productName: 'Packable Beach Towel / Perla (Free Size)',
            variant: "Perla",
            size: "+",
            price: "$55",
            sku: "000012",
            quantity: 1,
          },
        ],
        totalPrice: "$55",
        status: "DELIVERED",
        trackingNumber: "SEBG1307156373607108608",
      },
      {
        orderId: "1413AE45223",
        orderDate: "12/12/2024",
        productsOrdered: [
          {
            productId: '0',
            productImage: FH,
            productName: 'Martini Triangle Top / Vanilla Rum Jacquard (Medium)',
            variant: "Vanilla Rum Jacquard",
            size: "M",
            price: "$60",
            sku: "000013",
            quantity: 1,
          },
        ],
        totalPrice: "$60",
        status: "UNFULFILLED",
        trackingNumber: "SEBG1307156373607108608",
      }
    ]
  },
  {
    category: "Order Placed",
    orders: []
  },
  {
    category: "Unfulfilled",
    orders: [{
      orderId: "1413AE45223",
      orderDate: "12/12/2024",
      productsOrdered: [
        {
          productId: '0',
          productImage: FH,
          productName: 'Martini Triangle Top / Vanilla Rum Jacquard (Medium)',
          variant: "Vanilla Rum Jacquard",
          size: "M",
          price: "$60",
          sku: "000013",
          quantity: 1,
        },
      ],
      totalPrice: "$60",
      status: "UNFULFILLED",
      trackingNumber: "SEBG1307156373607108608",
    }]
  },
  {
    category: "Fulfilled",
    orders: []
  },
  {
    category: "In Transit",
    orders: []
  },
  {
    category: "Out for Delivery",
    orders: [{
      orderId: "467GHJKL1124",
      orderDate: "12/02/2024",
      productsOrdered: [
        {
          productId: '0',
          productImage: mockImage,
          productName: 'Floppy Hat / Banana Bunch (Free Size)',
          variant: "Banana Bunch",
          size: "+",
          price: "$55",
          sku: "000012",
          quantity: 1,
        },
        {
          productId: '1',
          productImage: FH,
          productName: 'Martini Triangle Top / Vanilla Rum Jacquard (Medium)',
          variant: "Vanilla Rum Jacquard",
          size: "M",
          price: "$55",
          sku: "000013",
          quantity: 1,
        },
        {
          productId: '2',
          productImage: VRJ,
          productName: 'Chloe Side Tie Cheeky Bottoms / NY Noir (Medium)',
          variant: "NY Noir",
          size: "M",
          price: "$55",
          sku: "000013",
          quantity: 1,
        },
        {
          productId: '3',
          productImage: CST,
          productName: 'Regina Cargo Pants / Basil Smash (Medium)',
          variant: "Basil Smash",
          size: "M",
          price: "$55",
          sku: "000013",
          quantity: 1,
        },
      ],
      totalPrice: "$220",
      status: "OUT FOR DELIVERY",
      trackingNumber: "SEBG1307156373607108608",
    }]
  },
  {
    category: "Delivered",
    orders: [{
      orderId: "1233BB45667",
      orderDate: "12/12/2024",
      productsOrdered: [
        {
          productId: '0',
          productImage: VRJ,
          productName: 'Packable Beach Towel / Perla (Free Size)',
          variant: "Perla",
          size: "+",
          price: "$55",
          sku: "000013",
          quantity: 1,
        },
      ],
      totalPrice: "$55",
      status: "DELIVERED",
      trackingNumber: "SEBG1307156373607108608",
    },]
  },
]

export const mockTrackingStatus = [
  {
    status: "Order created",
    message: "",
    date: "01/10/2024 11:14:49",
  },
  {
    status: "Shipment picked up",
    message: "",
    date: "01/10/2024 11:14:49",
  },
  {
    status: "In transit to sorting facility",
    message: "",
    date: "01/10/2024 11:14:49",
  },
  {
    status: "Shipment arrived at international hub",
    message: "",
    date: "01/10/2024 11:14:49",
  },
  {
    status: "Arrived at local sorting facility",
    message: "",
    date: "01/10/2024 11:14:49",
  },
  {
    status: "Out for delivery",
    message: "Your package is on its way and is expected to arrive today, 15 January 2024",
    date: "01/10/2024 11:14:49",
  },
]

export const mockShippingAddress = [
  {
    id: 1,
    country: "United States",
    company: "Havoc",
    addressLine1: "123 Maple Street IL",
    addressLine2: "",
    zipCode: 6274,
    city: {code: "spf", name: "Springfield"},
    phoneNumber: "+1 (217) 555-1234",
    isDefault: true,
  },
  {
    id: 2,
    country: "United States",
    company: "Softrigger",
    addressLine1: "1234 Elm Street Apt 567",
    addressLine2: "",
    zipCode: 90001,
    city: {code: "la", name: "Los Angeles, CA"},
    phoneNumber: "+1 (217) 555-1234",
    isDefault: false,
  },
];

export const mockMyRewardsList = [
  // mock status: used, ready, redeemable
  {
    id: 0,
    image: mockReward1,
    name: `Free Riri Triangle Top / Zooted`,
    points: 1500,
    status: 'redeemable', 
  },
  {
    id: 1,
    image: mockReward2,
    name: `Free Sticker Pack / Blackbough Beach Club coupon`,
    points: 1000,
    status: 'redeemable', 
  },
  {
    id: 2,
    image: mockReward3,
    name: `Free Sticker Pack / Valentine's Day coupon`,
    points: 500,
    status: 'redeemable', 
  },
  {
    id: 3,
    image: mockReward4,
    name: `Free Sticker Pack / Paradise Point coupon`,
    points: 250,
    status: 'redeemable', 
  },
  {
    id: 4,
    image: mockReward5,
    name: `Free Tote Bag / Coco Frio coupon`,
    points: 800,
    status: 'redeemable', 
  },
  {
    id: 5,
    image: mockReward2,
    name: `Free Sophia Ruched Cheeky Bottoms / Caribbean`,
    points: 3000,
    status: 'redeemable', 
  },
  {
    id: 6,
    image: mockReward5,
    name: `Free Tote Bag / Coco Frio coupon`,
    points: 2250,
    status: 'redeemable', 
  },
]

export const mockReferrals = [
  {
    title: 'Eliza Reyes',
    email: "e****@g***.com",
    dateCreated: "January 20, 2025",
    points: 5000,
  },
  {
    title: '',
    email: "e****@g***.com",
    dateCreated: "December 31, 2024",
    points: 2000,
  },
  {
    title: 'Jane Doe',
    email: '(j****@g***.com)',
    dateCreated: "November 2, 2024",
    points: 5000
  },
]

export const mockHistoryPoints = [
  {
    title: 'Wrote a review',
    dateCreated: "January 20, 2025",
    points: 5000
  },
  {
    title: 'Redeemed Points for Free Sticker Pack / Blackbough Beach Club coupon',
    dateCreated: "December 31, 2024",
    points: -200
  },
  {
    title: 'Placed an order',
    dateCreated: "November 2, 2024",
    points: 5000
  },
  {
    title: 'Birthday Gift Reward',
    dateCreated: "August 04, 2024",
    points: 5000
  },
  {
    title: 'Placed an order',
    dateCreated: "March 04, 2024",
    points: 5000
  },
  {
    title: 'Welcome to the Club',
    dateCreated: "November 2, 2024",
    points: 5000
  },
  {
    title: 'Placed an order',
    dateCreated: "November 2, 2024",
    points: 5000
  },
]